import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'font-bold text-white' : 'text-white';
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto px-6 flex justify-between items-center">
        <div className="text-2xl font-semibold text-gray-800">
          <Link to="/" className="text-gray-300 hover:text-white mx-2 transition-colors duration-300">Rohan Sharma</Link>
        </div>
        <div className="block lg:hidden">
          <button 
            onClick={() => setIsOpen(!isOpen)} 
            className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        {/* Full Menu */}
        <div className={`${
          isOpen ? 'block' : 'hidden'
        } lg:flex lg:items-center w-full lg:w-auto`}>
        <ul className="lg:flex lg:space-x-8 text-center lg:text-left">
          <li>
          <Link to="/" className={`${isActive('/')} hover:text-blue-600 transition-colors duration-300`}>
            Home
          </Link>
          </li>
          <li>
          <Link to="/services" className={`${isActive('/services')} hover:text-blue-600 transition-colors duration-300`}>
            Services
          </Link>
          </li>
          <li>
          <Link to="/work" className={`${isActive('/work')} hover:text-blue-600 transition-colors duration-300`}>
            Work
          </Link>
          </li>
          <li>
          <Link to="/awards" className={`${isActive('/awards')} hover:text-blue-600 transition-colors duration-300`}>
            Awards
          </Link>
          </li>
          <li>
          <Link to="https://r0hansh.github.io/" className="hover:text-blue-600 text-white transition-colors duration-300" target="_blank" rel="noopener noreferrer">
            Blog
          </Link>
          </li>
          <li>
          <Link to="/contact" className={`${isActive('/contact')} hover:text-blue-600 transition-colors duration-300`}>
            Contact
          </Link>
          </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
