import React from 'react';
import meesho from '../assets/meesho.png';
import pratilipi from '../assets/pratilipi.png';
import securelayer7 from '../assets/securelayer7.png';

const Work = () => {
  const experiences = [
    {
      company: "Meesho",
      logo: meesho,
      roles: [
        {
          title: "Security Engineer - III",
          duration: "Feb, 2024 - Present",
        }
      ],
      metrics: [
        "Drove ISO-27001:2022 compliance efforts as the infrastructure lead, developing required documentation, supporting internal and external audits and successfully achieving ISO-27001:2022 certification for Meesho.",
        "Evaluated and deployed most suitable CNAPP solution for Meesho among GCP SCC, Prisma Cloud, Wiz, Cloudanix and others. Remediated 100% of critical and high vulnerabilities in line with ISO-27001:2022, CIS GCP v3.0.0 and PCI DSS v4.0.1 standards.",
        "Led vulnerability assessments and penetration testing (VAPT), attack simulation for cloud infrastructure and incident detection and response using Cloudflare, Akamai WAF and a large-scale SIEM platform. Created custom firewall rules, incident detection rules and runbooks, reducing security incidents by 85%, lowering MTTD to near real-time and MTTR to less than 45 minutes and 3 hours for critical and high severity incidents respectively.",
        "Implemented IAM Zero Trust framework in GCP, enforcing the principle of least privilege and Just-In-Time (JIT) privileged access, preventing privilege escalation risks by 95% and eliminating misconfigurations in critical resources.",
        "Contributed to enhance the security of platforms used by Meesho by reporting 10+ critical and high security vulnerabilities to third-party security vendors, directly preventing potential breaches. Represented Meesho in a case study with our SIEM platform, highlighting improved threat detection and reduced incident response times by 60%-70%."
      ],
    },
    {
      company: "Pratilipi",
      logo: pratilipi,
      roles: [
        {
          title: "Security Engineer - II",
          duration: "Oct, 2022 - Feb, 2024",
        },
        {
          title: "Security Engineer - I",
          duration: "May, 2021 - Sept, 2022",
        },
        {
          title: "Security Engineer Intern",
          duration: "Mar, 2021 - Apr, 2021",
        }
      ],
      metrics: [
        "Assumed complete ownership of security layer at Pratilipi, overseeing product security, cloud security, IT corporate security and other aspects of information security.",
        "Successfully implemented a wide range of security best practices and procedures, such as vulnerability scanner, WAF hardening, OS hardening (AppArmor, Firejail), secrets sharing platform, google workspace security, secure infrastructure provisioning (terraform, ansible), ssh jumpbox, VPN, EDR software, SIEM, IDS/IPS, CI/CD pipeline security (secrets scanning, SBOMs, SCA, SAST, DAST), cloud security (AWS, GCP) and others, resulting in a 60% improvement in overall security posture and 80% reduction in identified vulnerabilities.",
        "Managed Pratilipi vulnerability disclosure program, developing and implementing policies and procedures for responsible disclosure, resulting in timely remediation of 95% reported vulnerabilities.",
        "Conducted comprehensive penetration testing for our applications and cloud infrastructure, identifying over 80 vulnerabilities and working closely with engineering and product teams to prioritize and mitigate risks.",
        "Collaborated with third-party security service providers to coordinate penetration testing exercises, ensuring alignment with company policies and achieving 100% compliance with internal security standards with audit."
      ],
    },
    {
      company: "SecureLayer7",
      logo: securelayer7,
      roles: [
        {
          title: "Security Consultant Intern",
          duration: "Jun, 2020 - Dec, 2020",
        },
      ],
      metrics: [
        "Conducted network security audit at India's largest thermal power plant, identifying 20+ critical gaps and improving security posture by 40%.",
        "Reported 40+ high-risk security vulnerabilities across web, mobile and thick-client applications through VAPT. Conducted N-day vulnerability research and published a PoC and blog post for a security vulnerability in Joomla CMS."
      ],
    }
  ];

  return (
    <section className="py-16 bg-gradient-to-r from-blue-100 via-white to-blue-50">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-8">Work History</h2>
        
        {/* Work Timeline */}
        <div className="relative">
          <div className="border-l-4 border-blue-500 pl-8">
            {experiences.map((exp, index) => (
              <div key={index} className="mb-12 flex items-start">
                <img 
                  src={exp.logo} 
                  alt={exp.company} 
                  className="h-12 w-12 rounded-full mr-6 shadow-md bg-white p-2" 
                />
                <div className="flex-grow bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">{exp.company}</h3>
                  <div className="text-gray-600 mb-4">
                    {exp.roles.map((role, i) => (
                      <div key={i} className="flex justify-between text-lg mb-2">
                        <span className="font-bold text-blue-600">{role.title}</span>
                        <span className="text-gray-500">{role.duration}</span>
                      </div>
                    ))}
                  </div>
                  <ul className="list-disc list-inside mt-4 text-gray-700 space-y-2">
                    {exp.metrics.map((metric, i) => (
                      <li key={i} className="px-4">{metric}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Skills Section */}
        <div className="mt-16">
          {/* Technical Skills */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <h3 className="text-3xl font-bold mb-6 text-gray-800 text-center">Technical Skills</h3>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 px-16">
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>Cloud Security, AWS, GCP</li>
                <li>Terraform, Ansible, Docker, Kubernetes</li>
                <li>Prisma Cloud, Cloudanix, Wazuh, Suricata, Zeek</li>
                <li>Bash, C, Go, Python, JavaScript, Assembly etc.</li>
                <li>Cyber Security Frameworks, Policies</li>
              </ul>
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>Application Security (Web, Mobile, Thick client)</li>
                <li>Secure Source-Code Review, Shift Left</li>
                <li>Vulnerability Assessment and Management</li>
                <li>Threat Modeling, Secure Architecture Review</li>
                <li>ISO 27001:2022, CIS Benchmarks, DPDPA 2023</li>
              </ul>
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>DevSecOps, CI/CD Pipeline</li>
                <li>Incident Detection and Response</li>
                <li>Threat Hunting and Intelligence</li>
                <li>Reverse Engineering, Program Analysis</li>
                <li>Akamai, Cloudflare, BurpSuite, CodeQL, VPNs</li>
              </ul>
            </div>
          </div>


          {/* Soft Skills */}
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h3 className="text-3xl font-bold text-gray-800 text-center mb-6">Soft Skills</h3>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 px-16">
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>Ethics and Integrity</li>
                <li>Effective Communication</li>
                <li>Adaptability</li>
              </ul>
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>High ownership and accountability</li>
                <li>Leadership</li>
                <li>Mentoring Junior Engineers</li>
              </ul>
              <ul className="list-disc ml-4 space-y-3 text-gray-700">
                <li>Result oriented</li>
                <li>Problem Solving</li>
                <li>Time Management</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
