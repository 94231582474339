import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto px-6">
        {/* Flexbox layout for desktop, stack vertically on smaller screens */}
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          {/* Left Section */}
          <div className="text-center md:text-left">
            <h2 className="text-lg font-semibold">Rohan Sharma</h2>
            <p className="text-sm">Securing startups and scaling security programs globally.</p>
          </div>

          {/* Social Links */}
          <div className="mt-4 text-center md:mt-0">
            <div className="space-x-6">
              <a href="https://www.linkedin.com/in/-rohan-sharma" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="https://x.com/r0hanSH" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
              <a href="https://www.github.com/r0hanSH" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400 transition-colors duration-300">
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
            <p className="mt-2 text-sm">&copy; {new Date().getFullYear()} Rohan Sharma. Trusted Security Parnter</p>
          </div>

          {/* Right Section (Contact Info) */}
          <div className="text-center md:text-right">
            <p className="text-sm">
              Email: <a href="mailto:rohansharma.rohan27@gmail.com" className="underline">rohansharma.rohan27@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
