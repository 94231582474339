import React from 'react';

const Contact = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-blue-100 via-white to-blue-50">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-8">Get in Touch</h2>
        <p className="text-lg mb-4 text-center">Interested in working together?</p>

        {/* Discovery Call Button */}
        <div className="flex justify-center mb-12">
          <a 
            href="https://calendly.com/rohan-security/discovery-call" 
            target="_blank" 
            rel="noopener noreferrer"
            className="px-6 py-2 bg-blue-600 text-white font-semibold text-xl rounded-full shadow-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Book a Discovery Call
          </a>

        </div>

        {/* Client Satisfaction Metrics */}
        <div className="bg-white p-8 rounded-lg shadow-md mb-12">
          <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">Client Satisfaction</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="p-6 bg-blue-50 rounded-lg shadow-sm">
              <h4 className="text-2xl font-semibold text-blue-600">95%</h4>
              <p className="text-gray-600">Client Satisfaction Rate</p>
            </div>
            <div className="p-6 bg-blue-50 rounded-lg shadow-sm">
              <h4 className="text-2xl font-semibold text-blue-600">85%</h4>
              <p className="text-gray-600">Client Conversion Rate</p>
            </div>
            <div className="p-6 bg-blue-50 rounded-lg shadow-sm">
              <h4 className="text-2xl font-semibold text-blue-600">15+</h4>
              <p className="text-gray-600">Successful Security Projects</p>
            </div>
          </div>
        </div>

        {/* Google Form Embed */}
        <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Send Me Your Query</h2>

        <div className="flex flex-col md:flex-row justify-between items-start gap-10">
          {/* Left Side: Google Form */}
          <div className="w-full md:w-1/2">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSeLWzUApF1mxxNT11CP3WXZUyVM_DTJhPwXvrH1LzDgZ9dGDw/viewform?embedded=true"
              width="100%"
              height="700"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              className="rounded-lg shadow-lg"
              title="Google Form"
            >
              Loading…
            </iframe>
          </div>

          {/* Right Side: Contact Information */}
          <div className="w-full md:w-1/2 p-6 bg-white rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Contact Information</h3>

            <p className="text-gray-600 mb-2">
              <strong>Name:</strong> Rohan Sharma
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Work:</strong> Security Consulting for Startups
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Email:</strong> rohansharma.rohan27@gmail.com
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Location:</strong> Bangalore, India
            </p>

          </div>
        </div>
      </div></div>

    </section>
  );
};

export default Contact;
