import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCloud, faCogs, faSearch, faLock, faCode } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const [highlightedService, setHighlightedService] = useState(null);

  useEffect(() => {
    // Check for URL hash when component mounts
    const hash = window.location.hash.substring(1); // Remove the '#' from the hash
    if (hash) {
      setHighlightedService(hash);

      // Remove highlight after 3 seconds
      setTimeout(() => {
        setHighlightedService(null);
      }, 3000);
    }
  }, []);

  return (
    <section className="py-16 bg-gradient-to-r from-blue-100 via-white to-blue-50">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-8">Featured Services</h2>
        <div className="flex justify-center mb-12">
          <a 
            href="https://calendly.com/rohan-security/discovery-call" 
            target="_blank" 
            rel="noopener noreferrer"
            className="px-6 py-2 bg-blue-600 text-white font-semibold text-xl rounded-full shadow-lg hover:bg-blue-700 transition-transform transform hover:scale-105"
          >
            Book a Discovery Call
          </a>

        </div>
        <br />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div id="application-penetration-testing" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'application-penetration-testing' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faShieldAlt} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">Application Penetration Testing</h3>
          <p className="text-gray-600 mb-6">
            Identify, exploit and fix the security vulnerabilities in your web and mobile applications. Get audit-ready pentest reports complying with various security compliance standards and frameworks.
            Identify the scope of work, Understand the business logic and critical aspects of applications, Identify any potential security risks that could by exploited by attackers and then provide in-depth analysis of your application security posture. Assist your team to reproduce the security findings and fix them, and validated the fixed vulnerabilities.
          </p>
        </div>

        <div id="cloud-security" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'cloud-security' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faCloud} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">Cloud Security</h3>
          <p className="text-gray-600 mb-6">
            Detect and fix security misconfigurations in cloud environments (CSP - AWS, GCP and Azure, SaaS) with automated and manual VAPT efforts. Focus on implementing best practices for securing your cloud infrastructure, data and applications. Ensure compliance with industry standards, provide guidance on continuous monitoring, security guardrails and advanced access control mechanisms to safeguard your cloud environment from threats.
          </p>
        </div>

        <div id="devsecops-consulting" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'devsecops-consulting' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faCogs} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">DevSecOps Consulting</h3>
          <p className="text-gray-600 mb-6">
            Secure your CI/CD pipeline following Shift Left, Secure by Default Principle and other security measures leading to robust automated security testing. Assist your team in implementing or improving your CI/CD pipeline by ensuring the resilient security controls with open-source or enterprise solutions for Secrets scanning, Software Composition Analysis (SCA), Software Bill of Materials (SBoMs), Static Application Security Testing (SAST), Dynamic Application Security Testing (DAST), IAST and RASP and early detection of security issues.
          </p>
        </div>

        <div id="secure-source-code-review" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'secure-source-code-review' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faCode} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">Secure Source Code Review</h3>
          <p className="text-gray-600 mb-6">
            Identify and mitigate security vulnerabilities in your application's codebase with thorough, expert-driven reviews to ensure safe and secure software development. Perform code analysis to uncover the hidden security issues.  Secure SDLC or coding training for your team to include threat modeling for early detection of security issues in design phase and then suggest secure code templates in different programming langauges to avoid introduction of security issues in development phase.
          </p>
        </div>

        <div id="security-compliance" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'security-compliance' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faLock} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">Security Compliance</h3>
          <p className="text-gray-600 mb-6">
            Achieve and maintain industry-standard security certifications, ensuring your business meets regulatory requirements with seamless compliance management. Simplify the process by aligning your security posture with industry standards such as GDPR, PCI DSS, HIPAA, and ISO 27001. Implement the necessary controls, ensuring both technical and operational readiness. Robust compliance strategy that strengthens your overall security framework while avoiding common pitfalls that lead to non-compliance fines.
          </p>
        </div>

        <div id="scaling-security-for-startups" className={`mb-10 p-8 shadow-md border rounded-md ${highlightedService === 'scaling-security-for-startups' ? 'bg-yellow-100 border-yellow-300' : 'bg-white'}`}>
          <div className="text-blue-600 text-6xl mb-4 text-center">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <h3 className="text-3xl font-semibold mb-4 text-center">Scaling Security for Startups</h3>
          <p className="text-gray-600 mb-6">
            Implement scalable security solutions tailored for startups, enabling rapid growth without compromising on security and protecting your business from emerging threats. Assist your team to focus on the right things for best ROI on security enhancements efforts rather than falling for security gimmicks. Focus on solving low-hanging critical risk security issues and implementing security controls on business critical assets which improves your security posture by big margin.
          </p>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
