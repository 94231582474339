import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCloud, faCogs, faSearch, faLock, faCode } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import apple from '../assets/apple.png';
import mozilla from '../assets/mozilla.png';
import paytm from '../assets/paytm.png';
import amazon from '../assets/amazon.png';
import redbull from '../assets/redbull.png';
import pratilipi from '../assets/pratilipi.png';
import att from '../assets/att.png';
import coralogix from '../assets/coralogix.png';

function Home() {
  return (
    <div className="bg-gray-100 overflow-x-hidden">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-700 via-white-200 to-slate-700 text-white text-center py-20">
        <h1 className="text-4xl font-bold">Security Consulting for Startups</h1>
        <p className="mt-4 text-lg font-semibold">Helping startups secure their applications, cloud environments and scale security</p>
        <div className="flex justify-center mt-8">
          <a 
            href="https://calendly.com/rohan-security/discovery-call" 
            target="_blank" 
            rel="noopener noreferrer"
            className="px-6 py-2 bg-yellow-500 text-white text-xl font-semibold rounded-full shadow-lg hover:bg-yellow-600 transition-transform transform hover:scale-105"
          >
            Book a Discovery Call
          </a>

        </div>
      </section>

      {/* About Me */}
      <section className="container mx-auto py-12 text-center">
        <h2 className="text-3xl font-bold mb-6">About Me</h2>
        <p className="text-lg mb-4">Senior Security Engineer with 3.5+ years of experience and a proven track record of success in security assessments, vulnerability management, incident response etc. Recognized for winning multiple CTF competitions and discovering CVEs in major products, with name included in security hall of fame of Mozilla, Apple, Mastodon and other major organizations. Possesses strong expertise in Application Security, Secure Source-Code Review, DevSecOps, Cloud Security, Purple Team Operations, Threat Hunting and Incident Response. Proactively assumes complete ownership of security layers at organizations, enabling comprehensive security coverage across all areas of operation.</p>
        <a href="https://www.linkedin.com/in/-rohan-sharma" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 transition-transform transform hover:scale-105">
              <FontAwesomeIcon icon={faLinkedin} size="2x"/>
            </a>
      </section>

      {/* Services */}
      <section className="bg-gradient-to-r from-blue-100 to-blue-50 py-16">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">Featured Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faShieldAlt} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Application Penetration Testing</h3>
              <p className="text-gray-600 mb-6">Identify, exploit and fix the security vulnerabilities in your web and mobile applications. Get audit-ready pentest reports complying with various security compliance standards and frameworks.</p>
              <a href="/services#application-penetration-testing" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faCloud} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Cloud Security</h3>
              <p className="text-gray-600 mb-6">Detect and fix security misconfigurations in cloud environments (CSP - AWS, GCP and Azure, SaaS) with automated and manual VAPT efforts.</p>
              <a href="/services#cloud-security" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faCogs} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">DevSecOps Consulting</h3>
              <p className="text-gray-600 mb-6">Secure your CI/CD pipeline following Shift Left, Secure by Default Principle and other security measures leading to robust automated security testing</p>
              <a href="/services#devsecops-consulting" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faCode} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Secure Source Code Review</h3>
              <p className="text-gray-600 mb-6">Identify and mitigate security vulnerabilities in your application's codebase with thorough, expert-driven reviews to ensure safe and secure software development.</p>
              <a href="/services#secure-source-code-review" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faLock} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Security Compliance</h3>
              <p className="text-gray-600 mb-6">Achieve and maintain industry-standard security certifications, ensuring your business meets regulatory requirements with seamless compliance management.</p>
              <a href="/services#security-compliance" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
            <div className="p-8 bg-white shadow-lg hover:shadow-xl transition-all transform hover:scale-105 rounded-lg">
              <div className="text-blue-600 text-6xl mb-4">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <h3 className="text-2xl font-semibold mb-4">Scaling Security for Startups</h3>
              <p className="text-gray-600 mb-6">Implement scalable security solutions tailored for startups, enabling rapid growth without compromising on security and protecting your business from emerging threats.</p>
              <a href="/services#scaling-security-for-startups" className="inline-block bg-blue-600 text-white py-2 px-6 rounded-full shadow-md hover:bg-blue-700 transition-colors duration-300">Learn More</a>
            </div>
          </div>
        </div>
      </section>

      {/* Achievements */}
      <section className="bg-gradient-to-r from-blue-700 via-white-200 to-slate-700 text-white py-16">
        <h2 className="text-3xl font-bold text-center mb-6">
          <a href="/awards">Achievements</a>
        </h2>
        <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-12">
          <div className="px-8 text-center">
            <h3 className="text-2xl font-bold">150+</h3>
            <p>Vulnerabilities Discovered</p>
          </div>
          <div className="px-8 text-center">
            <h3 className="text-2xl font-bold">18+</h3>
            <p>Published CVEs</p>
          </div>
          <div className="px-8 text-center">
            <h3 className="text-2xl font-bold">30+</h3>
            <p>Companies Secured</p>
          </div>
          <div className="px-8 text-center">
            <h3 className="text-2xl font-bold">15+</h3>
            <p>Awards & Recognitions</p>
          </div>
          <div className="px-8 text-center">
            <h3 className="text-2xl font-bold">40+</h3>
            <p>Exploits</p>
          </div>
        </div>
      </section>

      {/* Logos Marquee */}
      {/* Company logos section */}
      <section className="bg-gray-50 py-10 bg-gradient-to-r from-blue-100 via-white to-blue-50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Companies I Have Secured</h2>
          <div className="grid grid-cols-4 gap-8 mb-8">
            {/* First row of companies */}
            <div className="flex justify-center">
              <img src={redbull} alt="Redbull" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={mozilla} alt="Mozilla" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={apple} alt="Apple" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={att} alt="AT&T" className="h-16 rounded-lg"/>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            {/* Second row of companies */}
          <div className="flex justify-center">
              <img src={pratilipi} alt="Pratilipi" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={amazon} alt="Amazon" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={paytm} alt="Paytm" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={coralogix} alt="Coralogix" className="h-16 rounded-lg"/>
            </div>
          </div>
        </div>
      </section>


      {/* Contact Section */}
      <section className="bg-gradient-to-r from-blue-700 via-white-200 to-slate-700 text-white mx-auto py-16 text-center">
        <h2 className="text-3xl font-bold mb-6">Interested in Securing Your Startup?</h2>
        <p className="text-lg mb-4 font-semibold">Book a discovery call now to explore how I can help your team</p>
        <div className="flex justify-center mt-8">
          <a 
            href="https://calendly.com/rohan-security/discovery-call" 
            target="_blank" 
            rel="noopener noreferrer"
            className="px-6 py-2 bg-yellow-500 text-white font-semibold text-xl rounded-full shadow-lg hover:bg-yellow-700 transition-transform transform hover:scale-105"
          >
            Book a Discovery Call
          </a>

        </div>
      </section>

    </div>
  );
}


export default Home;
