import React from 'react';

import apple from '../assets/apple.png';
import mozilla from '../assets/mozilla.png';
import paytm from '../assets/paytm.png';
import amazon from '../assets/amazon.png';
import redbull from '../assets/redbull.png';
import pratilipi from '../assets/pratilipi.png';
import att from '../assets/att.png';
import coralogix from '../assets/coralogix.png';
import htb from '../assets/htb.png';
import ftl from '../assets/ftl.png';
import hackerearth from '../assets/hackerearth.png';

const Awards = () => {
  const CVEs = [
    {
      id: "CVE-2021-4221",
      product: "Mozilla Firefox",
      name: "URL Address Bar Spoofing",
      advisory: "https://www.mozilla.org/en-US/security/advisories/mfsa2021-38/#CVE-2021-4221",
    },
    {
      id: "CVE-2020-9995",
      product: "Apple macOS Server",
      name: "DOM-based XSS",
      advisory: "https://support.apple.com/en-in/HT211932",
    },
    {
      id: "CVE-2022-0432",
      product: "Mastodon (47k github stars)",
      name: "Prototype Pollution",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0432",
    },
    {
      id: "CVE-2022-0437",
      product: "Karma (12k github stars)",
      name: "DOM-based XSS",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0437",
    },
    {
      id: "CVE-2022-0686",
      product: "url-parse (22M weekly downloads)",
      name: "URL Parsing Confusion",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0686",
    },
    {
      id: "CVE-2022-0776",
      product: "reveal.js (67k github stars)",
      name: "DOM-based XSS",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0776",
    },
    {
      id: "CVE-2022-0268",
      product: "Grav (14.5k github stars)",
      name: "Stored XSS",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0268",
    },
    {
      id: "CVE-2022-0624",
      product: "parse-path (3M weekly downloads)",
      name: "URL Parsing Confusion",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-0624",
    },
    {
      id: "CVE-2022-1295",
      product: "fullpage.js (35k github stars)",
      name: "Prototype Pollution",
      advisory: "https://nvd.nist.gov/vuln/detail/CVE-2022-1295",
    },
    
  ];

  const ctfs = [
    {
      position: "1st",
      competition: "Microservices GameDay, AWS India",
      year: "Oct, 2023",
    },
    {
      position: "2nd",
      competition: "DevSecOps GameDay, AWS India",
      year: "Apr, 2023",
    },
    {
      position: "1st",
      competition: "AISS Cyber Hackathon, DSCI",
      year: "Dec, 2020",
    },
    {
      position: "2nd",
      competition: "Cipher Combat 3.0 - VAPT CTF, HackerEarth",
      year: "Oct, 2020",
    },
    {
      position: "1st",
      competition: "Cipher Combat CTF, HackerEarth",
      year: "Jan, 2020",
    },
    {
      position: "1st",
      competition: "InCTF - Online Quals, Team bi0s",
      year: "Nov, 2019",
    },
    {
      position: "2nd",
      competition: "AISS Cyber Hackathon - Online Quals, DSCI",
      year: "Nov, 2019",
    },
    {
      position: "1st",
      competition: "CompTIA Secure CTF, CompTIA",
      year: "Oct, 2019",
    },
    {
      position: "2nd",
      competition: "Cipher Combat CTF, HackerEarth",
      year: "Apr, 2019",
    },
  ];

  return (
    <section className="py-10 bg-gradient-to-r from-blue-100 via-white to-blue-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Awards and Achievements</h2>

        <div className="mb-10">
          <h3 className="text-2xl font-semibold text-gray-800 text-center mb-4">CVEs (18+)</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
            {CVEs.map((cve, index) => (
              <div
                key={index}
                className="border rounded-lg shadow-md p-4 transition-transform transform hover:scale-105 bg-gradient-to-r from-blue-100 to-blue-50"
              >
                <h4 className="font-bold">
                  <a href={cve.advisory} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                    {cve.id}
                  </a>
                </h4>
                <p className="text-gray-700 font-semibold">{cve.product}</p>
                <p className="text-gray-700 mb-4">{cve.name}</p>
                {cve.exploit && (
                  <a href={cve.exploit} target="_blank" rel="noopener noreferrer" className="text-blue-600 px-6 py-2 rounded-full shadow-lg hover:bg-green-200 transition-transform transform hover:scale-105">
                  View Exploit
                </a>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-10">
          <h3 className="text-2xl font-semibold text-gray-800 text-center mb-4">CTF Competitions (15+)</h3>
          <div className="text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {ctfs.map((ctf, index) => (
              <div
                key={index}
                className="border rounded-lg shadow-md p-4 transition-transform transform hover:scale-105 bg-gradient-to-r from-green-100 to-green-50"
              >
                <h4 className="font-bold">{ctf.position}</h4>
                <h4>{ctf.competition}</h4>
                <p className="text-gray-700">{ctf.year}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="container mx-auto mb-10 px-6">
          <h3 className="text-2xl font-semibold text-gray-800 text-center mb-4">HackTheBox Rank</h3>
          <div className="grid grid-cols-3 items-center border rounded-lg shadow-md py-4 transition-transform transform hover:scale-105 bg-gradient-to-r from-blue-100 to-blue-50">
            <div className="flex flex-col items-center justify-center px-4">
              <h4 className="font-bold">Guru</h4>
              <h4>Owned 29 Users, 29 Systems - Machines</h4>
              <p className="text-gray-800">Jan, 2020</p>
            </div>
            <div className="flex justify-center px-4">
              <img src={htb} alt="HackTheBox - Guru" className="h-16 rounded-lg object-cover"/>
            </div>
            <div className="flex flex-col items-center justify-center px-4">
              <h4 className="font-bold">2 Insane, 6 Hard</h4>
              <h4>9 Medium, 4 Easy</h4>
              <p className="text-gray-800"><a href="https://r0hansh.github.io/posts/hackthebox" target="_blank" rel="noopener noreferrer">Machines Pwned in 30 days</a></p>
            </div>
          </div>
        </div>


        <div className="container mx-auto px-6">
          <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4">Companies I Have Secured (30+)</h2>
          <div className="grid grid-cols-5 gap-8 mb-8">
            {/* First row of companies */}
            <div className="flex justify-center">
              <img src={paytm} alt="Paytm" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={mozilla} alt="Mozilla" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={apple} alt="Apple" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={amazon} alt="AT&T" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={ftl} alt="AT&T" className="h-16 rounded-lg"/>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-8">
            {/* Second row of companies */}
            <div className="flex justify-center">
              <img src={att} alt="Amazon" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={pratilipi} alt="Pratilipi" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={redbull} alt="Redbull" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={coralogix} alt="Coralogix" className="h-16 rounded-lg"/>
            </div>
            <div className="flex justify-center">
              <img src={hackerearth} alt="AT&T" className="h-16 rounded-lg"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
